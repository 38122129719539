<template>
    <div v-loading="loading">
        <!--项目信息-->
        <div class="f1">
            <!--报名中-->
            <div class="left" v-if="task.status == 3 && task.trade == ''">
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">等待邀请</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img
                            v-if="task.status == 3"
                            :src="websiteConfigs.sourceUrl+'/upload/image/todo_img1.png'"
                        />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">您已成功报名</p>
                            <p
                                class="desc"
                                v-if="
                                    task.enroll_my && task.enroll_my.create_time
                                "
                            >
                                您已于 {{
                                task.enroll_my.create_time
                                }} 成功报名参加此项目。
                            </p>
                            <p class="btns">
                                <el-button
                                    type="mini"
                                    class="btn"
                                    @click="enrollDraw = true"
                                    style="width:124px;"
                                >查看报名信息</el-button>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <!--评选期-->
            <div class="left" v-if="task.status == 4 && !task.trade">
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">等待邀请</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img
                            v-if="task.status == 3"
                            :src="websiteConfigs.sourceUrl+'/upload/image/todo_img1.png'"
                        />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">您已成功报名</p>
                            <p
                                class="desc"
                                v-if="
                                    task.enroll_my && task.enroll_my.create_time
                                "
                            >
                                您已于 {{
                                task.enroll_my.create_time
                                }} 成功报名参加此项目。
                            </p>
                            <p class="btns">
                                <el-button
                                    type="mini"
                                    class="btn"
                                    @click="enrollDraw = true"
                                    style="width:124px;"
                                >查看报名信息</el-button>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <!--接收邀请-->
            <div
                class="left"
                v-if="(task.status == 3||task.status == 4) && task.trade && task.trade.status==0"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">收到邀请</span>
                    <span class="jd_sy">剩余</span>
                    <span class="jd_time">
                        <counter
                            :endTime="task.invited_end_time"
                            @endCount="enrollEndEvent(task.tk)"
                        ></counter>
                    </span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img
                            v-if="task.status == 3||task.status == 4"
                            :src="websiteConfigs.sourceUrl+'/upload/image/todo_img2.png'"
                        />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">雇主向您发出了邀请！</p>
                            <p class="desc">以下是项目详情及雇主所提出的项目条件，请在雇主发出邀请的12小时内确认是否接受项目，超时则自动拒绝雇主的邀请。</p>
                            <p class="btns">
                                <el-button
                                    type="mini"
                                    class="btn"
                                    @click="showInvite(task.trade.trade_sn)"
                                    style="width:124px;"
                                >邀请详情</el-button>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <!--确认邀请超时-->
            <div
                class="left"
                v-if="(task.status == 3||task.status == 4) && task.trade && task.trade.status==-2"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">接受邀请超时</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img4.png'" />
                    </div>
                    <div class="content">
                        <p class="title">您没有回应雇主邀请</p>
                        <p class="desc">您在限定时间内没有回应。</p>
                        <p class="btns">
                            <el-button
                                type="mini"
                                class="btn"
                                @click="enrollDraw = true"
                                style="width:124px;"
                            >我的报名信息</el-button>
                        </p>
                    </div>
                </div>
            </div>

            <!--拒绝邀请-->
            <div
                class="left"
                v-if="(task.status == 3 || task.status == 4) && task.trade  && task.trade.status==-1"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">拒绝邀请</span>
                    <span class="jd_sy"></span>
                    <span class="jd_time"></span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img
                            v-if="task.status == 3||task.status == 4"
                            :src="websiteConfigs.sourceUrl+'/upload/image/todo_img0.png'"
                        />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">您未接受雇主的邀请</p>
                            <p class="desc">您未接受雇主发出的邀请，如果您仍然愿意参加此项目，可以尝试与雇主沟通修改邀请条件。</p>
                            <p class="btns"></p>
                        </template>
                    </div>
                </div>
            </div>

            <!--未中标-->
            <div class="left" v-if="task.status == -20 ">
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">未中标</span>
                    <span class="jd_sy"></span>
                    <span class="jd_time"></span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img5.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">您未中标</p>
                            <p class="desc">雇主已成功邀请其他人，欢迎下次报名。</p>
                            <p class="btns"></p>
                        </template>
                    </div>
                </div>
            </div>

            <!--托管履约保证金-->
            <div class="left" v-if="task.status == 6">
                <div class="top" v-if="task.trade && task.trade.is_bond==1">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">已接受邀请</span>
                    <span class="jd_sy">剩余</span>
                    <span class="jd_time">
                        <counter :endTime="task.bond_end_time" @endCount="enrollEndEvent(task.tk)"></counter>
                    </span>
                </div>
                <div class="top" v-else>
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">已接受邀请</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img10.png'" />
                    </div>
                    <div class="content">
                        <template v-if="task.trade && task.trade.is_bond==1">
                            <p class="title">您已经接受雇主邀请！</p>
                            <p
                                class="desc"
                            >雇主要求您托管履约保证金{{task.trade.bond}}元，请在倒计时内完成托管。如双方在24小时内成功托管酬金与履约保证金，则您可以开始为雇主执行项目。</p>
                            <p class="btns">
                                <el-button
                                    type="mini"
                                    class="btn"
                                    v-if="task.trade && task.trade.bond_time==0"
                                    @click="payTypeSelectDialog = true"
                                    style="width:124px;"
                                >立即托管</el-button>
                            </p>
                        </template>
                        <template v-else>
                            <p class="title">您已经接受雇主邀请！</p>
                            <p class="desc">请等待雇主托管酬金，如果雇主在24小时内成功托管酬金，则您可以开始为雇主执行项目。</p>
                        </template>
                    </div>
                </div>
            </div>

            <!--项目结束 - 平台强制结束项目-->
            <div
                class="left"
                v-if="task.status == 10 && task.enroll_my && task.trade=='' && task.finish_admin>0 && task.finish_time>0"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">项目已结束</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img5.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">平台结束项目</p>
                            <p class="desc">该项目被平台强制结束</p>
                            <p class="btns"></p>
                        </template>
                    </div>
                </div>
            </div>

            <!--项目结束 - 极客报名7天内雇主未发起邀请-->
            <div
                class="left"
                v-if="task.status == 10 && task.enroll_my && task.trade=='' && task.log_current.code < 31 && task.finish_admin==0 && task.finish_time==0"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">项目已结束</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img5.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">雇主未发出邀请</p>
                            <p class="desc">项目报名截止7天内，雇主未发出邀请，项目自动结束。</p>
                            <p class="btns"></p>
                        </template>
                    </div>
                </div>
            </div>

            <!--项目结束 - 雇主托管酬金超时-->
            <div class="left" v-if="task.status == 10 && task.trade && task.trade.status==-5">
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">项目已结束</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img5.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">托管酬金超时</p>
                            <p class="desc">雇主未在规定时间内托管项目酬金，项目自动结束。</p>
                            <p class="btns"></p>
                        </template>
                    </div>
                </div>
            </div>

            <!--项目结束 - 极客托管履约保证金超时-->
            <div class="left" v-if="task.status == 10 && task.trade && task.trade.status==-4">
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">项目已结束</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img5.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">托管履约保证金超时</p>
                            <p class="desc">您未在规定时间内托管履约保证金，项目自动结束。</p>
                            <p class="btns"></p>
                        </template>
                    </div>
                </div>
            </div>

            <!--工作中-->
            <div class="left" v-if="task.status == 7">
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">项目进行中</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img6.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">请开始工作</p>
                            <p
                                class="desc"
                            >您与雇主已完成了资金托管，请开始执行项目，雇主设置的成果交付日期为：{{task.trade.delivery_time}}。</p>
                            <p class="btns">
                                <a
                                    href="#mao04"
                                    class="btn task_progress_view"
                                    style="width:124px;"
                                >提交任务成果</a>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <!--已完成 - 未评价-->
            <div
                class="left"
                v-if="task.status == 8 && (task.appraise=='' || (task.appraise && task.appraise.geek_appraise_time==0))"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">项目已完成</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img8.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">酬金已支付</p>
                            <p class="desc">雇主已经成功支付酬金，快去评价一下雇主吧~</p>
                            <p class="btns">
                                <el-button
                                    type="mini"
                                    @click="task_appraise(task.tk)"
                                    class="btn"
                                    style="width:124px;"
                                >立即评价</el-button>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <!--已完成 - 已评价-->
            <div
                class="left"
                v-if="task.status == 8 && task.appraise && task.appraise.geek_appraise_time>0"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">项目已完成</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img8.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">已评价</p>
                            <p class="desc">该项目已成功完成，您已提交评价</p>
                            <p class="btns">
                                <el-button
                                    type="mini"
                                    @click="view_appraise(task.tk)"
                                    class="btn"
                                    style="width:124px;"
                                >查看评价</el-button>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <!--项目结束 - 雇主手动结束-->
            <div
                class="left"
                v-if="task.status == 10 && task.log_current && task.log_current.code >=31 && task.log_current.code <=37"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">项目结束</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img5.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">项目已结束</p>
                            <p class="desc">雇主已经主动关闭了这个项目。您可以点击以下按钮查看关闭原因。</p>
                            <p class="btns"></p>
                        </template>
                    </div>
                </div>
            </div>

            <!--项目结束 - 线下交易-->
            <div class="left" v-if="task.status==10 && task.trade && task.trade.is_remuneration!=1">
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">项目已结束</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img5.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">您已接受雇主的邀请！</p>
                            <p class="desc">您可以自行与雇主进行联系并线下完成交易，在线下交易中，请注意防范风险，避免损失。</p>
                            <p class="btns"></p>
                        </template>
                    </div>
                </div>
            </div>

            <!--调解中 - 3天内-->
            <template v-if="task.status == 9&& task.appeal_deal && task.appeal_deal.status==0">
                <template
                    v-if="task.appeal && task.appeal.length>0 && task.appeal[0].uk==userInfo.info.uk"
                >
                    <div class="left" v-if="task.status == 9 && task.trade && task.trade.status==5">
                        <div class="top">
                            <span class="jd_name">当前进度：</span>
                            <span class="jd_status">调解中</span>
                            <span class="jd_sy">剩余</span>
                            <span class="jd_time">
                                <counter
                                    :endTime="task.appeal_end_time"
                                    @endCount="enrollEndEvent(task.tk)"
                                ></counter>
                            </span>
                        </div>

                        <div class="bottom">
                            <div class="icon">
                                <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img7.png'" />
                            </div>
                            <div class="content">
                                <template>
                                    <p class="title">您已发起了调解申请</p>
                                    <p
                                        class="desc"
                                    >您已发起了调解申请，双方可以在3天内提交意见与证据，询龙网会根据双方所提交的意见与证据提出调解意见。</p>
                                    <p class="btns">
                                        <el-button
                                            type="mini"
                                            @click="appealDetail(task.tk,(task.appeal_deal.status==0)?0:1)"
                                            class="btn"
                                            style="width:124px;"
                                        >提交证明及资料</el-button>
                                    </p>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="left" v-if="task.status == 9 && task.trade && task.trade.status==5">
                        <div class="top">
                            <span class="jd_name">当前进度：</span>
                            <span class="jd_status">调解中</span>
                            <span class="jd_sy">剩余</span>
                            <span class="jd_time">
                                <counter
                                    :endTime="task.appeal_end_time"
                                    @endCount="enrollEndEvent(task.tk)"
                                ></counter>
                            </span>
                        </div>

                        <div class="bottom">
                            <div class="icon">
                                <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img7.png'" />
                            </div>
                            <div class="content">
                                <template>
                                    <p class="title">雇主已发起了调解申请</p>
                                    <p
                                        class="desc"
                                    >请注意，雇主已发起调解申请，请在3天内提交您的意见与证据。询龙网会根据双方所提交的意见与证据提出调解意见。您也可查看《询龙网服务协议》了解申请调解的相关流程与规则。</p>
                                    <p class="btns">
                                        <el-button
                                            type="mini"
                                            @click="appealDetail(task.tk,(task.appeal_deal.status==0)?0:1)"
                                            class="btn"
                                            style="width:124px;"
                                        >提交证明及资料</el-button>
                                    </p>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </template>

            <!--调解中 - 3天后-->
            <div
                class="left"
                v-if="task.status == 9 && task.appeal_deal && task.appeal_deal.status==1"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">调解中</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img7.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">请等待调解</p>
                            <p class="desc">询龙网邀请的第三方专家将根据双方所提交的意见与证据，给出调解意见，请耐心等待。</p>
                            <p class="btns">
                                <el-button
                                    type="mini"
                                    @click="appealDetail(task.tk)"
                                    class="btn"
                                    style="width:124px;"
                                >查看调解</el-button>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <!--调解中 - 收到调解意见-->
            <div
                class="left"
                v-if="task.status == 9 && task.appeal_deal && task.appeal_deal.status==2 && (task.appeal_deal.geek_status==0 && task.appeal_deal.employer_status==0)"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">调解中</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img7.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">已收到调解意见</p>
                            <p class="desc">您已经收到调解意见，请打开查看并确认。</p>
                            <p class="btns">
                                <el-button
                                    type="mini"
                                    @click="appealDetail(task.tk)"
                                    class="btn"
                                    style="width:124px;"
                                >查看调解意见</el-button>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <!--调解中 - 双方达成一致-->
            <div
                class="left"
                v-if="task.status == 10 && task.appeal_deal && task.appeal_deal.status==2 && task.appeal_deal.geek_status==1 && task.appeal_deal.employer_status==1"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">调解完成</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img8.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">双方已接受调解意见</p>
                            <p class="desc">双方已接受调解意见，询龙网将按照调解意见对已托管的资金进行分配。</p>
                            <p class="btns">
                                <el-button
                                    type="mini"
                                    @click="appealDetail(task.tk)"
                                    class="btn"
                                    style="width:124px;"
                                >查看资金分配</el-button>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <!--调解中 - 双方未就调解达成一致-->
            <div
                class="left"
                v-if="task.status == 9 && task.appeal_deal && task.appeal_deal.status==2 && (task.appeal_deal.geek_status==2 || task.appeal_deal.employer_status==2)"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">调解中</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img7.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">双方未就调解达成一致</p>
                            <p class="desc">双方未就调解意见达成一致，请您尽快向北京仲裁委员会提交仲裁。并在收到仲裁书后，通过此处上传。</p>
                            <p class="btns">
                                <el-button
                                    type="mini"
                                    @click="appealDetail(task.tk)"
                                    class="btn"
                                    style="width:124px;"
                                >上传仲裁书</el-button>
                            </p>
                        </template>
                    </div>
                </div>
            </div>
            <div
                class="left"
                v-if="task.status == 9 && task.appeal_deal && task.appeal_deal.status==2 && task.appeal_deal.geek_status!=2 && task.appeal_deal.employer_status!=2&& ((task.appeal_deal.geek_status==0 && task.appeal_deal.employer_status!=0 )|| (task.appeal_deal.geek_status!=0 && task.appeal_deal.employer_status==0 ))"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">调解中</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img7.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">已收到调解意见</p>
                            <p
                                class="desc"
                                v-if="task.appeal_deal.geek_status == 0"
                            >您已经收到调解意见，请打开查看并确认。</p>
                            <p class="desc" v-else>您已接受调解意见，请打开查看。</p>
                            <p class="btns">
                                <el-button
                                    type="mini"
                                    @click="appealDetail(task.tk)"
                                    class="btn"
                                    style="width:124px;"
                                >查看调解意见</el-button>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <!--调解中 - 调解完成，分配资金完成-->
            <div
                class="left"
                v-if="task.status == 10 && task.appeal_deal && task.appeal_deal.status==3 && task.appeal_deal.status==3 && task.appeal_deal.deal_appeal_time"
            >
                <div class="top">
                    <span class="jd_name">当前进度：</span>
                    <span class="jd_status">调解完成</span>
                </div>

                <div class="bottom">
                    <div class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_img8.png'" />
                    </div>
                    <div class="content">
                        <template>
                            <p class="title">资金分配完成</p>
                            <p class="desc">询龙网已按照北京仲裁委员会仲裁结果执行。</p>
                            <p class="btns">
                                <el-button
                                    type="mini"
                                    @click="appealDetail(task.tk)"
                                    class="btn"
                                    style="width:124px;"
                                >查看资金分配</el-button>
                            </p>
                        </template>
                    </div>
                </div>
            </div>

            <div class="right">
                <p class="title">
                    <span class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/title_icon.png'" />
                    </span>
                    <span>项目信息</span>
                </p>

                <ul>
                    <li>
                        <span class="key">项目名称：</span>
                        <span class="value">{{ task.task_name }}</span>
                    </li>

                    <li>
                        <span class="key">项目编号：</span>
                        <span class="value">{{ task.sn }}</span>
                    </li>

                    <li>
                        <span class="key">项目模式：</span>
                        <span class="value">{{ task.task_type_value }}</span>
                    </li>

                    <!--项目有交易信息-->
                    <template v-if="task.trade">
                        <li>
                            <span class="key">履约保证金：</span>
                            <span class="value">{{ 1==task.trade.is_bond ? "需要托管" : "不需要托管" }}</span>
                        </li>
                        <li v-if="1==task.trade.is_bond">
                            <span class="key">履约保证金金额：</span>
                            <span class="value">{{ task.trade.bond }}元</span>
                        </li>
                        <li>
                            <span class="key">项目酬金：</span>
                            <span class="value">{{ task.trade.remuneration }} 元</span>
                        </li>
                    </template>
                    <template v-else>
                        <!-- <li>
                            <span class="key">履约保证金：</span>
                            <span class="value">{{ 1==task.is_bond ? "需要托管" : "不需要托管" }}</span>
                        </li> -->
                        <li v-if="1==task.is_bond">
                            <span class="key">履约保证金金额：</span>
                            <span class="value">{{ task.bond }}元</span>
                        </li>
                        <li>
                            <span class="key">项目酬金：</span>
                            <span class="value">{{ task.remuneration }} 元</span>
                        </li>
                    </template>
                </ul>

                <div class="btns">
                    <a href="javascript:;" @click="taskDetail(task.tk)">查看项目详情</a>
                </div>
            </div>
        </div>

        <!--工具栏-->
        <div
            class="f2"
            v-if="task && task.enroll_my && 3== task.enroll_my.status && task.status != -20"
        >
            <ul>
                <li class="item">
                    <span class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/tools_icon1.png'" />
                    </span>
                    <span class="text">
                        <a
                            href="javascript:;"
                            @click="contractStep"
                            v-if="task.contract && (task.contract.status == 1 || task.contract.status == 2 || (task.contract.status == 0 && (task.contract.geek_address || task.contract.employer_address)))"
                            style="color:#587ddc;"
                        >电子合同签署中</a>
                        <a
                            href="javascript:;"
                            @click="contractStep"
                            v-else-if="task.contract && task.contract.status == 3"
                        >查看电子合同</a>
                        <a href="javascript:;" @click="contractStep" v-else>签署电子合同</a>
                    </span>
                </li>

                <li class="item" v-if="task.status>6 && task.status<9">
                    <span class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/tools_icon2.png'" />
                    </span>
                    <span class="text">
                        <a href="javascript:;" @click="appeal(task.tk)">提交调解申请</a>
                    </span>
                </li>

                <li class="item" v-else>
                    <span class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/tools_icon2.png'" />
                    </span>
                    <span class="text">
                        <a href="javascript:;" style="cursor:not-allowed">提交调解申请</a>
                    </span>
                </li>

                <li class="item">
                    <span class="icon">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/tools_icon3.png'" />
                    </span>
                    <span class="text">
                        <a href="javascript:;" @click="viewFile(task.tk)" style="width:124px;">查看资料库</a>
                    </span>
                </li>
            </ul>
        </div>

        <!--项目进度-->
        <div class="f3">
            <div
                style="width:100%;font-size:16px;margin-left:20px;height:40px;font-weight:normal;"
            >项目进度</div>
            <el-steps :active="task.geek_node_index" align-center>
                <el-step
                    v-for="(item, index) in task.geek_node"
                    :key="index"
                    :title="item.title"
                    :description="item.desc"
                ></el-step>
            </el-steps>
        </div>

        <!--项目状态-->
        <div class="f4">
            <!--已报名-->
            <div
                :class="task.status==3 && task.enroll_my && task.trade==''?'item':'item gray'"
                v-if="task.enroll_my"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">报名</p>
                        <p class="num">01</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon1.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">已成功报名</span>
                        <span class="datetime">{{ task.enroll_my.create_time }}</span>
                    </div>
                    <div class="bottom">
                        <p class="text">您已成功报名此项目，请耐心等待雇主评选。</p>
                    </div>
                </div>

                <div class="right">
                    <div class="btns">
                        <el-button
                            type="mini"
                            class="btn"
                            @click="enrollDraw = true"
                            style="width:124px;"
                        >查看报名信息</el-button>
                    </div>
                </div>
            </div>

            <!--雇主发出邀请超时-->
            <div
                :class="task.status == 3 && task.enroll && task.enroll.status==-3 ?'item':'item gray'"
                v-if="task.status == 3 && task.enroll && task.enroll.status==-3"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">邀请</p>
                        <p class="num">02</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon2.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">雇主发出邀请超时</span>
                        <span class="datetime">{{ task.approve_time }}</span>
                    </div>
                    <div class="bottom" v-if="task.employer">
                        <p class="text">雇主</p>
                        <p class="avatar">
                            <img :src="util.reImg(task.employer.avatar)" />
                        </p>
                        <p class="text" v-if="task.trade">
                            <a
                                class="link"
                                href="javascript:;"
                                @click="employerDetail(task.employer.uk)"
                            >{{ task.trade.geek.name }}</a>
                        </p>
                        <p class="text">您报名后未在规定时间内收到雇主发出的邀请，该邀请自动失效，您可以再次报名该项目或者其他项目。</p>
                    </div>
                </div>

                <div class="right">
                    <div class="content">
                        <span>请尽快查看邀请</span>
                    </div>
                    <div class="btns">
                        <el-button
                            v-if="task.trade && task.trade.status == 0"
                            type="mini"
                            class="btn"
                            @click="dealInvite(task.tk, 1)"
                            style="width:124px;"
                        >接受邀请</el-button>

                        <el-button
                            v-if="task.trade && task.trade.status == 0"
                            type="mini"
                            @click="dealInvite(task.tk, 2)"
                            style="width:124px;"
                        >拒绝邀请</el-button>

                        <el-button
                            v-if="task.trade && (task.trade.status > 0 || task.trade.status==-1)"
                            type="mini"
                            class="btn"
                            @click="showInvite(task.trade.trade_sn)"
                            style="width:124px;"
                        >查看邀请</el-button>
                    </div>
                </div>
            </div>

            <!--邀请信息列表-->
            <div v-for="(item,idx) in task.trade_list" :key="idx">
                <div class="item gray" v-if="-1==item.status || -2==item.status">
                    <div class="left">
                        <div class="block1">
                            <p class="title">邀请</p>
                            <p class="num">02</p>
                        </div>
                        <div class="block2"></div>
                        <div class="cir">
                            <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon2.png'" />
                        </div>
                    </div>

                    <div class="center">
                        <div class="top">
                            <!-- <span class="title">邀请</span> -->
                            <span class="title" style="color:red;" v-if="-1==item.status">已拒绝邀请</span>
                            <span class="title" style="color:red;" v-if="-2==item.status">确认邀请超时</span>
                            <span class="datetime">{{item.create_time}}</span>
                        </div>
                        <div class="bottom">
                            <p class="text">您已拒绝雇主</p>
                            <p class="avatar">
                                <img :src="util.reImg(task.employer.avatar)" />
                            </p>
                            <p class="text">
                                <a
                                    class="link"
                                    href="javascript:;"
                                    @click="geekDetail(task.employer.uk)"
                                    style="width:124px;"
                                >{{ task.employer.name }}</a>
                            </p>
                            <p class="text">&nbsp;&nbsp;发出的邀请。</p>
                        </div>
                    </div>

                    <div class="right">
                        <!-- <div class="content">
                            <span v-if="-1==item.status">工程极客已拒绝邀请</span>
                            <span v-if="-2==item.status">工程极客确认邀请超时</span>
                        </div>-->
                        <div class="btns" v-if="task.trade">
                            <el-button
                                type="mini"
                                class="btn"
                                @click="showInvite(item.trade_sn)"
                                style="width:124px;"
                            >邀请详情</el-button>
                        </div>
                    </div>
                </div>
            </div>

            <!--已收到邀请-->
            <div
                :class="task.status==3 && task.trade && task.trade.status==0 ?'item':'item gray'"
                v-if="task.status>=3 && task.trade && (task.trade.status==0)"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">邀请</p>
                        <p class="num">02</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon2.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">接到邀请</span>
                        <span class="datetime">{{ task.approve_time }}</span>
                    </div>
                    <div class="bottom" v-if="task.employer">
                        <p class="text">雇主</p>
                        <p class="avatar">
                            <img :src="util.reImg(task.employer.avatar)" />
                        </p>
                        <p class="text" v-if="task.trade">
                            <a
                                class="link"
                                href="javascript:;"
                                @click="employerDetail(task.employer.uk)"
                            >{{ task.employer.name }}</a>
                        </p>
                        <p class="text">向您发出了邀请，请在雇主发出邀请的12小时内确认是否接受此项目，超时则自动拒绝雇主的邀请。</p>
                    </div>
                </div>

                <div class="right">
                    <div class="content">
                        <span>请尽快查看邀请</span>
                    </div>

                    <div class="btns">
                        <el-button
                            v-if="task.trade && task.trade.status == 0"
                            type="mini"
                            class="btn"
                            @click="showInvite(task.trade.trade_sn)"
                            style="width:124px;"
                        >查看邀请</el-button>
                    </div>
                </div>
            </div>

            <!--已接受邀请-->
            <div
                :class="task.status==3 && task.trade && task.trade.status>=2 ?'item':'item gray'"
                v-if="task.status>=3 && task.trade && task.trade.status>=2"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">邀请</p>
                        <p class="num">02</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon2.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">已接受邀请</span>
                        <span class="datetime">{{ task.approve_time }}</span>
                    </div>
                    <div class="bottom" v-if="task.employer">
                        <p class="text">您已接受雇主</p>
                        <p class="avatar">
                            <img :src="util.reImg(task.employer.avatar)" />
                        </p>
                        <p class="text" v-if="task.trade">
                            <a
                                class="link"
                                href="javascript:;"
                                @click="employerDetail(task.employer.uk)"
                                style="width:124px;"
                            >{{ task.employer.name }}</a>
                        </p>
                        <p class="text">&nbsp;&nbsp;发出的邀请。</p>
                    </div>
                </div>

                <div class="right">
                    <div class="btns">
                        <el-button
                            v-if="task.trade && task.trade.status > 0"
                            type="mini"
                            class="btn"
                            @click="showInvite(task.trade.trade_sn)"
                            style="width:124px;"
                        >查看邀请</el-button>
                    </div>
                </div>
            </div>

            <!-- 未中标 -->
            <div :class="'item'" v-if="task.status == -20 ">
                <div class="left">
                    <div class="block1">
                        <p class="title">竞标</p>
                        <p class="num">{{task.trade_list.length == 0 ?'02':'03'}}</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon2.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">您未中标</span>
                        <span class="datetime">{{ task.approve_time }}</span>
                    </div>
                    <div class="bottom" v-if="task.employer">
                        <p class="text">您未中标，雇主已成功邀请其它人，欢迎下次报名。</p>
                    </div>
                </div>

                <div class="right">
                    <div class="btns">
                        <el-button
                            v-if="task.trade && task.trade.status > 0"
                            type="mini"
                            class="btn"
                            @click="showInvite(task.trade.trade_sn)"
                            style="width:124px;"
                        >查看邀请</el-button>
                    </div>
                </div>
            </div>

            <!--托管履约保证金-->
            <div
                :class="task.status==6 && task.trade && task.trade.status==2 ?'item':'item gray'"
                v-if="task && task.trade && task.trade.status >= 2 && task.trade.is_remuneration==1"
                style="height:210px;"
            >
                <div class="left" style="height:210px;">
                    <div class="block1" style="height:210px;">
                        <p class="title">托管</p>
                        <p class="num">03</p>
                    </div>
                    <div class="block2" style="height:210px;"></div>
                    <div class="cir" style="top: 55px;">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon3.png'" />
                    </div>
                </div>
                <div class="center" style="height:210px;">
                    <div class="top">
                        <span class="title">托管资金情况</span>
                        <span class="datetime">{{ task.trade.create_time }}</span>
                    </div>

                    <div class="bottom">
                        <table style="width:100%;" cellspacing="0">
                            <tbody style="width:100%;">
                                <tr class="li" v-if="task.employer">
                                    <td style="width:80px;padding:5px;">雇主</td>
                                    <td style="padding-left:5px;" colspan="2">
                                        <a
                                            class="link"
                                            href="javascript:;"
                                            @click="employerDetail(task.employer.uk)"
                                            style="color:#587ddc;"
                                        >{{ task.employer.name }}</a>
                                    </td>
                                </tr>
                                <tr class="li" v-if="task.employer">
                                    <td
                                        style="width:80px;border-bottom:1px solid #ddd;padding:5px;"
                                    >托管酬金</td>

                                    <td style="padding-left:5px;border-bottom:1px solid #ddd;">
                                        <span>{{ task.trade.remuneration }} 元</span>
                                    </td>

                                    <td
                                        style="text-align:right;border-bottom:1px solid #ddd;width: 210px;color:#50e878"
                                        v-if="task.trade && task.trade.is_remuneration != 1"
                                    >
                                        <div style="width: 210px;color:#666;">无需托管</div>
                                    </td>

                                    <td
                                        style="text-align:right;border-bottom:1px solid #ddd;width: 210px;color:#50e878"
                                        v-if="task.trade && task.trade.is_remuneration == 1 && task.trade.remuneration_time > 0"
                                    >
                                        <div
                                            style="width: 210px;color:#666;"
                                        >{{task.trade.remuneration_time_value}} 已托管</div>
                                    </td>

                                    <td
                                        style="text-align:right;border-bottom:1px solid #ddd;width: 210px;color:red"
                                        v-if="task.trade && task.trade.is_remuneration == 1 && task.trade.remuneration_time <= 0"
                                    >
                                        <div style="width: 210px;color:#666;">未托管</div>
                                    </td>

                                    <td
                                        style="text-align:right;border-bottom:1px solid #ddd;width: 210px;color:red"
                                        v-if="task.trade && task.trade.is_remuneration == 1 && task.trade.status == -5"
                                    >
                                        <div style="width: 210px;color:#666;">托管超时</div>
                                    </td>
                                </tr>

                                <tr class="li" v-if="task.trade && task.trade.geek">
                                    <td style="width:80px;padding:5px;">极客</td>
                                    <td style="padding-left:5px;" colspan="2">
                                        <a
                                            class="link"
                                            href="javascript:;"
                                            @click="geekDetail(task.trade.geek.uk)"
                                            style="color:#587ddc"
                                        >{{ task.trade.geek.name }}</a>
                                    </td>
                                </tr>
                                <tr class="li" v-if="task.trade && task.trade.geek">
                                    <td
                                        style="width:80px;border-bottom:1px solid #ddd;padding:5px;"
                                    >履约保证金</td>

                                    <td
                                        style="padding-left:5px;border-bottom:1px solid #ddd;width:180px;"
                                    >
                                        <span>{{ task.trade.bond }} 元</span>
                                    </td>

                                    <td
                                        style="text-align:right;border-bottom:1px solid #ddd;width: 210px;color:red"
                                        v-if="task.trade && task.trade.is_bond == 1 && task.trade.bond_time <= 0"
                                    >
                                        <div
                                            style="width: 210px;color:#666;"
                                            @click="payTypeSelectDialog = true"
                                        >未托管</div>
                                    </td>

                                    <td
                                        style="text-align:right;border-bottom:1px solid #ddd;width: 210px;"
                                        v-if="task.trade &&task.trade.is_bond == 1 &&task.trade.bond_time > 0"
                                    >
                                        <div
                                            style="width: 210px;color:#666;"
                                        >{{task.trade.bond_time_value}} 已托管</div>
                                    </td>

                                    <td
                                        style="text-align:right;border-bottom:1px solid #ddd;width: 210px;color:#50e878"
                                        v-if="task.trade && task.trade.is_bond != 1"
                                    >
                                        <div style="width: 210px;color:#666;">无需托管</div>
                                    </td>

                                    <td
                                        style="text-align:right;border-bottom:1px solid #ddd;width: 210px;color:red"
                                        v-if="task.trade &&task.trade.is_bond == 1 &&task.trade.status == -5"
                                    >
                                        <div style="width: 210px;color:#666;">托管超时</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="right" style="height:210px;line-height: 136px;">
                    <div class="content">
                        <template v-if="task.trade && task.trade.is_bond==1">
                            <span v-if="task.trade && task.trade.bond_time>0">您已完成托管</span>
                            <el-button
                                type="mini"
                                class="btn"
                                v-else
                                @click="payTypeSelectDialog = true"
                                style="width:124px;"
                            >立即托管</el-button>
                        </template>
                        <template v-else>
                            <span>无需托管</span>
                        </template>
                    </div>
                    <div class="btns" v-if="task.trade && task.trade.is_bond==1">
                        <el-button
                            type="mini"
                            class="btn"
                            v-if="task.trade && task.trade.bond_time==0"
                            @click="payTypeSelectDialog = true"
                            style="width:124px;"
                        >托管履约保证金</el-button>
                    </div>
                </div>
            </div>

            <!--项目结束 - 极客报名7天内雇主未发起邀请-->
            <div
                :class="task.status == 10 && task.enroll_my && task.trade=='' && task.finish_admin==0 && task.finish_time==0?'item':'item gray'"
                v-if="task.status == 10 && task.enroll_my && task.trade=='' && task.log_current.code < 31 && task.finish_admin==0 && task.finish_time==0"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">结束</p>
                        <p class="num"></p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon5.png'" />
                    </div>
                </div>

                <div class="center">
                    <div class="top">
                        <span class="title">项目结束</span>
                        <span class="datetime">{{task.appeal_deal.deal_appeal_time}}</span>
                    </div>

                    <div class="bottom">
                        <p class="text">项目报名截止7天内雇主未发起邀请。</p>
                        <p class="text" style="color:#587ddc"></p>
                    </div>
                </div>

                <div class="right">
                    <div class="btns"></div>
                </div>
            </div>

            <!--项目结束 - 平台强制结束-->
            <div
                :class="task.status == 10 && task.enroll_my && task.trade=='' && task.finish_admin>0 && task.finish_time>0 ? 'item':'item gray'"
                v-if="task.status == 10 && task.enroll_my && task.trade=='' && task.finish_admin>0 && task.finish_time>0 "
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">结束</p>
                        <p class="num">02</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon5.png'" />
                    </div>
                </div>

                <div class="center">
                    <div class="top">
                        <span class="title">项目结束</span>
                        <span class="datetime">{{task.finish_time_text}}</span>
                    </div>

                    <div class="bottom">
                        <p class="text">该项目被平台强制结束，此项目目前已结束。结束原因如下：</p>
                        <p class="text" style="color:#587ddc">{{task.finish_description}}</p>
                    </div>
                </div>

                <div class="right">
                    <div class="btns"></div>
                </div>
            </div>

            <!--项目结束 - 雇主托管酬金超时-->
            <div
                :class="task.status == 10 && task.trade && task.trade.status==-5?'item':'item gray'"
                v-if="task.status == 10 && task.trade && task.trade.status==-5"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">结束</p>
                        <p class="num"></p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon5.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">项目结束</span>
                        <span class="datetime">{{task.appeal_deal.deal_appeal_time}}</span>
                    </div>
                    <div class="bottom">
                        <p class="text">雇主未在规定时间内托管项目酬金，项目自动结束。</p>
                    </div>
                </div>

                <div class="right">
                    <div class="btns"></div>
                </div>
            </div>

            <!--项目结束 - 雇主托管酬金超时-->
            <div
                :class="task.status == 10 && task.trade && task.trade.status==-4?'item':'item gray'"
                v-if="task.status == 10 && task.trade && task.trade.status==-4"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">结束</p>
                        <p class="num"></p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon5.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">项目结束</span>
                        <span class="datetime">{{task.appeal_deal.deal_appeal_time}}</span>
                    </div>
                    <div class="bottom">
                        <p class="text">您未在规定时间内托管履约保证金，项目自动结束。</p>
                    </div>
                </div>

                <div class="right">
                    <div class="btns"></div>
                </div>
            </div>

            <!--工作中-->
            <div
                id="mao04"
                :class="task.status==7 && task.trade && task.trade.status==3 ?'item':'item gray'"
                v-if="task && task.trade && task.trade.status >= 3 && task.trade.is_remuneration==1"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">工作</p>
                        <p class="num">04</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon3.png'" />
                    </div>
                </div>
                <div class="center" style="position:relative;">
                    <div class="top">
                        <span class="title">工作中</span>
                        <span class="datetime">{{ task.trade.create_time }}</span>
                    </div>

                    <div class="bottom">
                        <p class="text">您正在为雇主</p>
                        <p class="avatar">
                            <img :src="util.reImg(task.employer.avatar)" />
                        </p>
                        <p class="text">
                            <a
                                class="link"
                                href="javascript:;"
                                @click="geekDetail(task.employer.uk)"
                            >{{ task.employer.name }}</a> 执行这个项目，
                        </p>
                        <p class="text">您可以打开下方卷展栏提交进度、成果，以及提醒雇主支付酬金。</p>
                    </div>
                    <div class="tools" v-if="task_progress_box == false">
                        <el-button @click="task_progress_view">== 展开查看支付详情 ==</el-button>
                    </div>
                    <div class="tools" v-if="task_progress_box == true">
                        <el-button @click="task_progress_view">== 收起查看支付详情 ==</el-button>
                    </div>
                </div>

                <div class="right">
                    <div class="btns">
                        <el-button
                            type="mini"
                            class="btn"
                            @click="task_progress_view"
                            style="width:124px;"
                        >查看进度</el-button>
                    </div>
                </div>
            </div>

            <!-- 工作中-项目进度 -->
            <div
                class="td-item-box step1-box step4-box"
                v-if="task_progress_box == true && task && task.trade && task.trade.status >= 3 && task.trade.is_remuneration==1"
                style="border:1px solid #eee;"
            >
                <div class="progress_box_left">
                    <div class="br-bd" v-if="task && task.trade">
                        <el-timeline>
                            <!-- 分阶段支付已支付状态 通过在 el-timeline-item上添加class="active" 来区分开始状态，通过设置button按钮的type来控制button颜色，是否可点用:disabled="true"或:disabled="false"控制-->

                            <!------分段支付 - 极客-------->
                            <el-timeline-item
                                class="active"
                                :timestamp="item | doneTimeFilter"
                                placement="top"
                                v-for="(item,index) in task.trade.block_geek"
                                :key="index"
                            >
                                <el-card>
                                    <div class="card-bd">
                                        <div class="cardbd-l">
                                            <h4 class="tit mb-10" v-text="item.title"></h4>
                                            <p>
                                                阶段酬金：
                                                <span class="color1">￥{{item.remuneration}}</span>
                                            </p>
                                            <template v-if="task.appeal_deal">
                                                <p v-if="item.status==-1">阶段状态：已结束</p>
                                                <p v-if="item.status==0">阶段状态：调解</p>
                                                <p v-if="item.status==1">阶段状态：已提交阶段成果</p>
                                                <p v-if="item.status==2">阶段状态：正在申请酬金</p>
                                                <p v-if="item.status==3">阶段状态：酬金已支付完成（{{item.done_time}}）</p>
                                            </template>
                                            <template v-else>
                                                <p v-if="item.status==0">阶段状态：阶段工作未开始</p>
                                                <p v-if="item.status==1">阶段状态：已提交阶段成果</p>
                                                <p v-if="item.status==2">阶段状态：正在申请酬金</p>
                                                <p v-if="item.status==3">阶段状态：酬金已支付完成 （{{item.done_time}}）</p>
                                                <p v-if="item.status==10">
                                                    阶段状态：
                                                    <span style="color:#16ec16">阶段工作进行中</span>
                                                </p>
                                            </template>
                                        </div>

                                        <div class="cardbd-r" v-if="task.appeal_deal">
                                            <template v-if="item.status==3">
                                                <div class="btn-item mb-10">
                                                    <el-button disabled type="primary">已获得酬金</el-button>
                                                </div>
                                                <div class="btn-item mb-10">
                                                    <el-button
                                                        type="primary"
                                                        @click="viewResult(task.tk,item.id)"
                                                    >查看阶段成果</el-button>
                                                </div>
                                            </template>

                                            <template v-if="item.status==2">
                                                <div class="btn-item mb-10">
                                                    <el-button disabled type="primary">正在申请支付酬金</el-button>
                                                </div>
                                                <div class="btn-item mb-10">
                                                    <el-button
                                                        type="primary"
                                                        disabled
                                                        @click="viewResult(task.tk,item.id)"
                                                    >补充阶段成果</el-button>
                                                </div>
                                            </template>

                                            <template v-if="item.status==1">
                                                <div class="btn-item mb-10">
                                                    <el-button
                                                        type="primary"
                                                        disabled
                                                        @click="payRem(task.tk,item.id)"
                                                    >申请支付酬金</el-button>
                                                </div>
                                                <div class="btn-item mb-10">
                                                    <el-button
                                                        type="primary"
                                                        disabled
                                                        @click="viewResult(task.tk,item.id)"
                                                    >修改阶段成果</el-button>
                                                </div>
                                            </template>

                                            <template v-if="item.status==10">
                                                <div class="btn-item mb-10">
                                                    <el-button type="primary" disabled>争议阶段</el-button>
                                                </div>
                                            </template>

                                            <template v-if="item.status==0">
                                                <div class="btn-item mb-10">
                                                    <el-button type="primary" disabled>非争议阶段</el-button>
                                                </div>
                                            </template>

                                            <template v-if="item.status==-1">
                                                <div class="btn-item mb-10">
                                                    <el-button type="primary" disabled>已退回非争议酬金</el-button>
                                                </div>
                                            </template>
                                        </div>

                                        <div class="cardbd-r" v-else>
                                            <template v-if="item.status==3">
                                                <div class="btn-item mb-10">
                                                    <el-button disabled type="primary">已获得酬金</el-button>
                                                </div>
                                                <div class="btn-item mb-10">
                                                    <el-button
                                                        type="primary"
                                                        @click="viewResult(task.tk,item.id)"
                                                    >查看阶段成果</el-button>
                                                </div>
                                            </template>

                                            <template v-if="item.status==2">
                                                <div class="btn-item mb-10">
                                                    <el-button disabled type="primary">正在申请支付酬金</el-button>
                                                </div>
                                                <div class="btn-item mb-10">
                                                    <el-button
                                                        type="primary"
                                                        @click="viewResult(task.tk,item.id)"
                                                    >补充阶段成果</el-button>
                                                </div>
                                            </template>

                                            <template v-if="item.status==1">
                                                <div class="btn-item mb-10">
                                                    <el-button
                                                        type="primary"
                                                        @click="payRem(task.tk,item.id)"
                                                    >申请支付酬金</el-button>
                                                </div>
                                                <div class="btn-item mb-10">
                                                    <el-button
                                                        type="primary"
                                                        @click="viewResult(task.tk,item.id)"
                                                    >修改阶段成果</el-button>
                                                </div>
                                            </template>

                                            <template v-if="item.status==10">
                                                <div class="btn-item mb-10">
                                                    <el-button disabled type="primary">申请支付酬金</el-button>
                                                </div>

                                                <div
                                                    class="btn-item mb-10"
                                                    v-if="task.trade && task.trade.block_geek[index] && task.trade.block_geek[index]['status']==10"
                                                >
                                                    <el-button
                                                        type="primary"
                                                        @click="viewResult(task.tk,item.id)"
                                                    >提交阶段成果</el-button>
                                                </div>
                                                <div class="btn-item mb-10" v-else>
                                                    <el-button type="primary" disabled>提交阶段成果</el-button>
                                                </div>
                                            </template>

                                            <template v-if="item.status==0">
                                                <div class="btn-item mb-10">
                                                    <el-button disabled type="primary">申请支付酬金</el-button>
                                                </div>

                                                <div
                                                    class="btn-item mb-10"
                                                    v-if="task.trade && task.trade.block_geek[index] && task.trade.block_geek[index]['status']==10"
                                                >
                                                    <el-button
                                                        type="primary"
                                                        @click="viewResult(task.tk,item.id)"
                                                    >提交阶段成果</el-button>
                                                </div>
                                                <div class="btn-item mb-10" v-else>
                                                    <el-button type="primary" disabled>提交阶段成果</el-button>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </el-card>
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                    <div style="clear:both;width:100%;height:1px;"></div>
                </div>

                <div class="ver-line progress_box_right">
                    <h3 class="it-tit bl-hd">项目进度</h3>
                    <div class="bl-bd">
                        <div class="des-it">
                            已获得酬金：
                            <span class="color1">{{ task.payed_remuneration_geek }} 元</span>
                        </div>
                        <div class="des-it">
                            平台服务费：
                            <span class="color1">5%</span>
                        </div>
                    </div>
                </div>
            </div>

            <!--工作已完成-->
            <div
                :class="task.status==8?'item':'item gray'"
                v-if="task.status==8 && task.trade && task.trade.is_remuneration==1"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">完成</p>
                        <p class="num">05</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon4.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">项目完成</span>
                        <span class="datetime">{{task.trade.create_time}}</span>
                    </div>
                    <div class="bottom" v-if="task.employer">
                        <p class="text">雇主</p>
                        <p class="avatar">
                            <img :src="util.reImg(task.employer.avatar)" />
                        </p>
                        <p class="text">
                            <a
                                class="link"
                                href="javascript:;"
                                @click="geekDetail(task.employer.uk)"
                            >{{ task.employer.name }}</a> 已确认支付了本项目的所有酬金，
                        </p>
                        <p class="text">项目已完成，别忘记评价对方哦！</p>
                    </div>
                </div>

                <div class="right">
                    <div class="content" v-if="task.appraise">
                        <span v-if="task.appraise.employer_appraise_time>0">对方已评价</span>
                        <span v-else>对方未评价</span>
                    </div>
                    <div class="content" v-else>
                        <span>没有评价信息</span>
                    </div>
                    <div class="btns">
                        <el-button
                            type="mini"
                            class="btn"
                            v-if="task.appraise.geek_appraise_time>0"
                            @click="view_appraise(task.tk)"
                            style="width:124px;"
                        >查看评价</el-button>
                        <el-button type="mini" class="btn" v-else @click="task_appraise(task.tk)" style="width:124px;">评价</el-button>
                    </div>
                </div>
            </div>

            <!--项目结束-线下交易-->
            <div
                :class="task.status==10?'item':'item gray'"
                v-if="task.status==10 && task.trade && task.trade.is_remuneration!=1"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">结束</p>
                        <p class="num">03</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon4.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">项目结束</span>
                        <span class="datetime">{{task.trade.create_time}}</span>
                    </div>
                    <div class="bottom" v-if="task.employer">
                        <p class="text">您已接受雇主</p>
                        <p class="avatar">
                            <img :src="util.reImg(task.employer.avatar)" />
                        </p>
                        <p class="text">
                            <a
                                class="link"
                                href="javascript:;"
                                @click="geekDetail(task.employer.uk)"
                            >{{ task.employer.name }}</a>
                        </p>
                        <p class="text">线下执行项目的邀请，您可以自行与雇主进行联系并线下完成交易，在线下交易中，请注意防范风险，避免损失。</p>
                    </div>
                </div>
            </div>

            <!--项目结束 - 雇主主动结束项目-->
            <div
                :class="task.status==10 && task.log_current && task.log_current.code>=31 && task.log_current.code<=37?'item':'item gray'"
                v-if="task.status==10 && task.log_current && task.log_current.code>=31 && task.log_current.code<=37"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">结束</p>
                        <p class="num"></p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon5.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">项目结束</span>
                        <span class="datetime">{{task.log_current.create_time}}</span>
                    </div>
                    <div class="bottom">
                        <p class="text">雇主已主动结束了项目，此项目目前已结束。雇主所提交的结束原因如下：</p>
                        <p class="text" style="color:#587ddc">{{task.finish_description}}</p>
                    </div>
                </div>

                <div class="right"></div>
            </div>

            <!--调解发起 3天内-->
            <div
                :class="task.appeal_deal && task.appeal_deal.status==0?'item':'item gray'"
                v-if="task.appeal_deal && task.status != -20"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">调解</p>
                        <p class="num">05</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon4.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">发起调解</span>
                        <span class="datetime">{{task.appeal_deal.create_time}}</span>
                    </div>
                    <!--工程极客发起-->
                    <template v-if="task.appeal[0].type==1">
                        <div class="bottom" v-if="task.trade && task.trade.geek">
                            <p
                                class="text"
                            >您已发起了调解申请，请在72小时（3天）内提交调解证明及资料。调解证明资料提交截止时间：{{task.appeal_end_time_value}}</p>
                        </div>
                    </template>

                    <!--雇主极客发起-->
                    <template v-else>
                        <div class="bottom" v-if="task.employer">
                            <p class="text">工程极客</p>
                            <p class="avatar">
                                <img :src="util.reImg(task.employer.avatar)" />
                            </p>
                            <p class="text">
                                <a
                                    class="link"
                                    href="javascript:;"
                                    @click="geekDetail(task.employer.uk)"
                                >{{ task.employer.name }}</a>
                            </p>
                            <p
                                class="text"
                            >已发起了调解申请，请在72小时（3天）内提交调解证明及资料。调解证明资料提交截止时间：{{task.appeal_end_time_value}}</p>
                        </div>
                    </template>
                </div>
                <div class="right">
                    <div class="btns">
                        <el-button
                            type="mini"
                            class="btn"
                            @click="appealDetail(task.tk,(task.appeal_deal.status==0)?0:1)"
                            style="width:124px;"
                        >提交证明及资料</el-button>
                    </div>
                </div>
            </div>

            <!--调解发起 3天后 - 等待调解-->
            <div
                :class="task.appeal_deal && task.appeal_deal.status==1?'item':'item gray'"
                v-if="task.appeal_deal && task.appeal_deal.status>=1 && task.status != -20"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">调解</p>
                        <p class="num">06</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon4.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">调解阶段</span>
                        <span class="datetime">{{task.appeal_deal.add_appeal_end}}</span>
                    </div>
                    <div class="bottom" v-if="task.employer">
                        <p class="text">询龙网邀请的第三方专家将根据双方所提交的意见与证据，给出调解意见，请耐心等待。</p>
                    </div>
                </div>
                <div class="right">
                    <div class="btns">
                        <el-button
                            type="mini"
                            class="btn"
                            @click="appealDetail(task.tk)"
                            style="width:124px;"
                        >查看调解</el-button>
                    </div>
                </div>
            </div>

            <!--调解 - 收到调解意见-->
            <div
                :class="task.appeal_deal && (task.appeal_deal.geek_status==0 || task.appeal_deal.employer_status==0) && task.appeal_deal.status>=2?'item':'item gray'"
                v-if="task.appeal_deal && task.appeal_deal.content && task.appeal_deal.status>=2 && task.status != -20"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">调解</p>
                        <p class="num">07</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon4.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">收到调解意见</span>
                        <span class="datetime">{{task.appeal_deal.content_time}}</span>
                    </div>
                    <div class="bottom">
                        <p class="text">您已经收到调解意见，请打开查看并确认。</p>
                    </div>
                </div>

                <div class="right">
                    <div class="btns">
                        <el-button
                            type="mini"
                            class="btn"
                            @click="appealDetail(task.tk)"
                            style="width:124px;"
                        >查看调解意见</el-button>
                    </div>
                </div>
            </div>

            <!--调解 - 双方未达成一致-->
            <div
                :class="task.appeal_deal&& (task.appeal_deal.geek_status==2 || task.appeal_deal.employer_status==2) && task.appeal_deal.status!=3?'item':'item gray'"
                v-if="task.appeal_deal&& (task.appeal_deal.geek_status==2 || task.appeal_deal.employer_status==2) && task.appeal_deal.status>=2 && task.status != -20"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">调解</p>
                        <p class="num">08</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon4.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">处理调解</span>
                        <span
                            class="datetime"
                        >{{task.appeal_deal.geek_deal_time?task.appeal_deal.geek_deal_time:task.appeal_deal.employer_deal_time}}</span>
                    </div>
                    <div class="bottom">
                        <p class="text">双方未就调解意见达成一致，请您尽快向北京仲裁委员会提交仲裁。并在收到仲裁书后，通过此处上传。</p>
                    </div>
                </div>
                <div class="right">
                    <div class="btns">
                        <el-button type="mini" class="btn" @click="appealDetail(task.tk)">上传仲裁书</el-button>
                    </div>
                </div>
            </div>

            <!--调解中 - 双方达成一致-->
            <div
                :class="task.appeal_deal && task.appeal_deal.geek_status==1 && task.appeal_deal.employer_status==1 && task.appeal_deal.status==3?'item':'item gray'"
                v-if="task.appeal_deal && task.appeal_deal.geek_status==1 && task.appeal_deal.employer_status==1 && task.appeal_deal.status==3 && task.status != -20"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">调解</p>
                        <p class="num">08</p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon4.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">处理调解</span>
                        <span
                            class="datetime"
                        >{{task.appeal_deal.geek_deal_time?task.appeal_deal.geek_deal_time:task.appeal_deal.employer_deal_time}}</span>
                    </div>
                    <div class="bottom">
                        <p class="text">双方已接受调解意见，询龙网将按照调解意见对已托管的资金进行分配。</p>
                    </div>
                </div>
                <div class="right">
                    <div class="btns">
                        <el-button type="mini" class="btn" @click="appealDetail(task.tk)">查看资金分配</el-button>
                    </div>
                </div>
            </div>

            <!--调解完成 - 项目结束-->
            <div
                :class="(task.status==10 && task.appeal_deal && task.appeal_deal.status==3) ?'item':'item gray'"
                v-if="task.status==10 && task.appeal_deal && task.appeal_deal.status==3"
            >
                <div class="left">
                    <div class="block1">
                        <p class="title">结束</p>
                        <p class="num"></p>
                    </div>
                    <div class="block2"></div>
                    <div class="cir">
                        <img :src="websiteConfigs.sourceUrl+'/upload/image/todo_icon5.png'" />
                    </div>
                </div>
                <div class="center">
                    <div class="top">
                        <span class="title">项目结束</span>
                        <span class="datetime">{{task.appeal_deal.deal_appeal_time}}</span>
                    </div>
                    <div class="bottom">
                        <p class="text">调解完成，资金分配已完成，项目结束。</p>
                        <p class="text" style="color:#587ddc">{{task.finish_description}}</p>
                    </div>
                </div>

                <div class="right">
                    <div class="btns">
                        <el-button
                            type="mini"
                            class="btn"
                            @click="appealDetail(task.tk)"
                            style="width:124px;"
                        >查看资金分配</el-button>
                    </div>
                </div>
            </div>
        </div>

        <!--我的报名信息-->
        <el-drawer
            custom-class="list-draw"
            :before-close="closeDraw"
            :visible.sync="enrollDraw"
            type="primary"
            title="我的报名信息"
        >
            <div class="invited_detail" style="padding:0 30px;" v-if="task.enroll_my">
                <el-form>
                    <el-form-item label="我的报价" label-width="30%">{{ task.enroll_my.price }} 元</el-form-item>
                    <el-form-item
                        label="预报工期"
                        label-width="30%"
                        v-if="task.task_type == 3"
                    >{{ task.enroll_my.work_time }} 天</el-form-item>
                    <el-form-item label="每日酬金" label-width="30%" v-if="task.task_type == 3">
                        {{(task.enroll_my.price / task.enroll_my.work_time).toFixed(2)}}
                        元/天
                    </el-form-item>
                    <el-form-item label="联系人" label-width="30%">{{task.enroll_my.contact}}（对方不可见）</el-form-item>
                    <el-form-item label="联系人电话" label-width="30%">{{task.enroll_my.phone}}（对方不可见）</el-form-item>
                    <el-form-item label="报名附件" label-width="30%">
                        <el-link
                            v-if="task.enroll_my.annex_zip"
                            :href="websiteConfigs.sourceUrl + task.enroll_my.annex_zip"
                        >下载</el-link>
                        <span v-else>无</span>
                    </el-form-item>
                    <el-form-item label="报名留言" label-width="30%">
                        <p
                            style="word-break: break-all;word-wrap: break-word;max-height:250px;overflow-y: scroll;"
                        >{{task.enroll_my.content}}</p>
                    </el-form-item>
                </el-form>
            </div>
        </el-drawer>

        <!-- 极客托管履约保证金弹窗  start -->
        <el-dialog
            class="blue-top-border subs-dialog subys-dialog"
            title="托管履约保证金"
            :visible.sync="payBondDialog"
            width="400px"
            center
            append-to-body
            @close="dialogClose"
        >
            <div class="notprov-box">
                <p
                    v-if="pay_type == 'Y01'"
                >您正在进行托管履约保证金操作，当您输入支付密码确认托管后，系统将从您的可用余额里进行扣除，将此保证金托管到系统里，项目结束或者完成后将退回到您的可用账户余额里。如果可用余额不足，请到我的钱包里充值后再进行托管或使用其他方式支付。</p>
                <p
                    v-if="pay_type == 'W01' || pay_type == 'A01'"
                >您正在进行托管履约保证金操作，当您扫码支付后，系统将此保证金托管到系统里，项目结束或者完成后将退回到您的可用账户余额里。</p>
                <ul class="hosting-list hostings-list" style="margin:0;padding:0;">
                    <template v-if="pay_type == 'Y01'">
                        <li class="hosting-item apl-item">
                            <span style="margin:0;">托管金额</span>
                            <i class="warn">{{ task.trade.bond }} 元（余额：{{userInfo.info.surplus}} 元）</i>
                        </li>

                        <li class="hosting-item">
                            <span style="margin:0;">支付密码</span>
                        </li>

                        <li class="hosting-item">
                            <el-input
                                v-model="pay_password"
                                type="password"
                                placeholder="请输入支付密码完成托管"
                            ></el-input>
                        </li>
                    </template>

                    <template v-if="pay_type == 'W01'">
                        <li
                            style="margin-top:30px;text-align:center;font-weight:normal;font-size:16px;"
                        >微信扫码支付</li>
                        <li
                            style="font-size:12px;text-align:center;line-height:30px;color:#74A5F7"
                        >支付金额：{{ pay_price }} 元</li>
                        <li class="hosting-item" style="text-align:center;">
                            <img
                                style="width:160px;border:2px dashed #ddd;padding:10px;border-radius:8px;"
                                :src="pay_img"
                            />
                        </li>
                        <li style="font-size:12px;text-align:center;color:#999;">请用微信扫描二维码进行支付</li>
                        <li style="height:30px;">&nbsp;</li>
                    </template>

                    <template v-if="pay_type == 'A01'">
                        <li
                            style="margin-top:30px;text-align:center;font-weight:normal;font-size:16px;"
                        >支付宝扫码支付</li>
                        <li
                            style="font-size:12px;text-align:center;line-height:30px;color:#74A5F7"
                        >支付金额：{{ pay_price }} 元</li>
                        <li class="hosting-item" style="text-align:center;">
                            <img
                                style="width:160px;border:2px dashed #ddd;padding:10px;border-radius:8px;"
                                :src="pay_img"
                            />
                        </li>
                        <li style="font-size:12px;text-align:center;color:#999;">请用支付宝扫描二维码进行支付</li>
                        <li style="height:30px;">&nbsp;</li>
                    </template>
                </ul>
            </div>
            <span slot="footer" class="dialog-footer" v-if="pay_type == 'Y01'">
                <el-button type="primary" @click="payBond">确认托管</el-button>
                <el-button type="info" @click="payBondDialog = false">拒绝托管</el-button>
            </span>
        </el-dialog>
        <!-- 极客托管履约保证金弹窗  end -->

        <!-- 极客弹窗申请酬金支付  start -->
        <el-dialog
            class="blue-top-border subs-dialog subys-dialog"
            title="申请酬金支付"
            :visible.sync="payDialog"
            width="400px"
            center
            append-to-body
        >
            <div class="notprov-box">
                <p>是否确认已提交所有阶段成果并向雇主申请支付阶段酬金</p>
                <ul class="hosting-list hostings-list">
                    <li class="hosting-item apl-item">
                        <span>申请支付金额</span>
                        <i class="warn">￥22000.00</i>
                    </li>
                </ul>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary">确认</el-button>
                <el-button type="info" @click="payDialog = false">取消</el-button>
            </span>
        </el-dialog>
        <!-- 极客弹窗申请酬金支付  end -->

        <!--支付方式选择-->
        <el-dialog
            class="blue-top-border"
            title="支付方式选择"
            :visible.sync="payTypeSelectDialog"
            width="500px"
            center
        >
            <div class="pointCoupon-buy">
                <div class="other-buy" style="border:none;">
                    <ol class="buy-way">
                        <li @click="bond('Y01')">
                            <span class="buy-icon">
                                <i class="jk-icon-yuezhifu"></i>
                            </span>
                            <strong>余额支付</strong>
                        </li>
                        <li @click="bond('W01')">
                            <span class="buy-icon">
                                <i class="jk-icon-weixinzhifu"></i>
                            </span>
                            <strong>微信支付</strong>
                        </li>
                        <li @click="bond('A01')">
                            <span class="buy-icon">
                                <i class="jk-icon-zhifubaozhifu"></i>
                            </span>
                            <strong>支付宝支付</strong>
                        </li>
                    </ol>
                </div>
            </div>
        </el-dialog>
        <!--支付方式选择 end-->
    </div>
</template>

<script>
import Counter from "@/components/common/Counter.vue";
export default {
    name: "todoGeek",
    props: ["userInfo", "task"],
    components: {
        Counter
    },
    data() {
        return {
            enrollDraw: false, //我的报名信息
            payDialog: false, //申请支付酬金对话框
            payBondDialog: false, //托管履约保证金
            pay_password: "", //支付密码
            pay_type: "", //支付方式
            pay_img: "", //支付二维码
            pay_price: "", //支付价格
            payTypeSelectDialog: false, //支付方式选择对话框
            timer: 0, //查询支付是否成功定时器
            loading: false,
            task_progress_box: false //项目进度
        };
    },
    filters: {
        doneTimeFilter: function (val) {
            if (val && val.done_time) {
                return "项目阶段预计完成时间：" + val.done_time;
            } else {
                return "项目阶段预计完成时间：";
            }
        }
    },
    methods: {
        //查看我的邀请信息
        showEnroll: function () { },

        //查看项目详情
        taskDetail: function (tk) {
            this.$emit("taskDetail", tk);
        },

        //查看极客详情
        employerDetail(uk, isShowDraw = "") {
            this.$emit("employerDetail", uk);
        },

        //查看极客详情
        geekDetail(uk, isShowDraw = "") {
            this.$emit("geekDetail", uk);
        },

        //发送消息
        sendMessage(uk, isShowDraw = "") {
            if (isShowDraw) {
                this.$emit("sendMessage", { uk: uk, param: "isShowDraw" });
            } else {
                this.$emit("sendMessage", { uk: uk });
            }
        },

        //邀请详情
        showInvite(trade_sn) {
            this.$emit("showInvite", trade_sn);
        },

        //关闭抽屉
        closeDraw() {
            this.enrollDraw = false; // 关闭我的报名信息抽屉
            sessionStorage.removeItem("isShowDraw"); //移除抽屉状态缓存
        },

        //报名倒计时截止
        enrollEndEvent: function (tk) {
            this.$emit("enrollEndEvent", tk);
        },

        //其他状态截止
        endCount: function () { },

        //接受雇主邀请
        dealInvite(tk, type) {
            let _this = this;
            _this.loading = true;
            _this.post(
                "/task/task/deal_invite",
                {
                    tk: tk,
                    type: type
                },
                function (res) {
                    _this.loading = false;
                    if (res.code == 200) {
                        _this.$message.success("操作成功");
                        _this.$emit("reload");
                        return;
                    } else {
                        if (res.msg) {
                            _this.$message.error(res.msg);
                        } else {
                            _this.$message.error("提交失败");
                        }
                    }
                }
            );
        },

        //填写合同签署信息
        contractUpdate(tk, uk) {
            this.$router.push(
                "/user/task/update_contract?tk=" + tk + "&uk=" + uk
            );
        },

        //查看电子合同预览
        showContract(tk, uk) {
            this.$emit("showContract", { tk: tk, uk: uk });
        },

        //查看已签署的电子合同预览
        showSignContract(tk, uk) {
            this.$emit("showSignContract", { tk: tk, uk: uk });
        },

        //查看阶段成果
        viewResult(tk, id) {
            this.$router.push("/user/task/result?tk=" + tk + "&id=" + id);
        },

        //合同签署
        contractSign(tk) {
            this.$emit("contractSign", { uk: tk });
        },

        //托管履约保证金
        bond(type = "") {
            this.pay_type = type;
            this.payTypeSelectDialog = false;
            if (type == "Y01") {
                if (this.userInfo.info.has_pay_password != 1) {
                    this.$message.error(
                        "您还未设置支付密码，请到账户安全设置支付密码后再进行托管酬金操作!"
                    );
                    //缓存当前页面链接，方面后面设置成功后跳转
                    sessionStorage.setItem(
                        "currentPageUrl",
                        document.location.pathname + document.location.search
                    );
                    //跳转到设置支付密码界面
                    this.$router.push(
                        "/user/validpassword?type=1&routerName=userSettingPassword&title=设置支付密码"
                    );
                    return false;
                }

                if (
                    parseFloat(this.userInfo.info.surplus) <
                    parseFloat(this.task.trade.bond)
                ) {
                    this.$message.error(
                        "您的可用余额不足，请到我的钱包中心充值后再来托管履约保证金！"
                    );
                    return false;
                }
                this.payBondDialog = true; //打开输入密码支付对话框
            } else {
                this.payBond(); //如果是微信或者支付宝支付则创建订单
            }
        },

        //查看项目进度
        task_progress_view() {
            let task_progress_box = this.task_progress_box;
            if (task_progress_box == true) {
                this.task_progress_box = false; //查看项目进度
                sessionStorage.setItem("task_progress_status", 2);
            } else {
                this.task_progress_box = true; //查看项目进度
                sessionStorage.setItem("task_progress_status", 1);
            }
        },

        //托管履约保证金事件
        payBond() {
            let _this = this;
            _this.payBondDialog = false;
            if (_this.pay_type == "Y01") {
                if (!_this.pay_password) {
                    _this.$message.error("请输入支付密码");
                    return false;
                }
            }
            _this.loading = true;
            _this.post(
                "/task/task/pay_bond",
                {
                    tk: _this.$route.query.tk,
                    password: _this.pay_password,
                    pay_type: _this.pay_type
                },
                function (res) {
                    _this.loading = false;
                    if (200 == res.code) {
                        _this.pay(res.data);
                    } else {
                        if (res.msg) {
                            _this.$message.error(res.msg);
                        } else {
                            _this.$message.error("托管失败，请重试");
                        }
                    }
                }
            );
        },

        //确认托管事件
        pay(order_no) {
            let _this = this;
            _this.pay_img = "";
            _this.pay_price = "";
            _this.loading = true;
            _this.payBondDialog = false;
            _this.order_no = order_no;
            _this.post(
                "/pay/pay/pay",
                { order_no: order_no, password: _this.pay_password },
                function (res) {
                    _this.loading = false;
                    if (200 == res.code) {
                        if (_this.pay_type == "Y01") {
                            _this.$message.success("托管成功");
                            _this.payBondDialog = false;
                            _this.$emit("reload"); //刷新数据
                        } else {
                            _this.pay_img = _this.websiteConfigs.sourceUrl + res.data.img;
                            _this.pay_price = res.data.price;
                            _this.payBondDialog = true; //打开二维码扫码对话框
                            _this.payTypeSelectDialog = false;
                            _this.timer = setInterval(() => {
                                _this.isPayCheck();
                            }, 2000);
                        }
                    } else {
                        if (res.msg) {
                            _this.$message.error(res.msg);
                        } else {
                            _this.$message.error("托管失败，请重试");
                        }
                    }
                }
            );
        },

        //对话框关闭事件
        dialogClose() {
            clearInterval(this.timer);
            this.payBondDialog = false;
            this.loading = false;
        },

        //查看调解详情
        appealDetail(tk, type = 0) {
            if (type == 1) {
                this.$message.error("提交调解资料时间已过，请耐心等待询龙网专家的调解意见");
            } else {
                this.$router.push("/user/task/appeal_detail?tk=" + tk);
            }
        },

        //是否支付成功
        isPayCheck() {
            let _this = this;
            this.post(
                "/pay/pay/is_pay",
                {
                    order_no: _this.order_no
                },
                function (res) {
                    if (res.code == 200) {
                        _this.$message.success("恭喜你，支付成功！");
                        clearInterval(_this.timer);
                        _this.payBondDialog = false;
                        _this.$emit("reload");
                    }
                }
            );
        },

        //更新阶段成果
        payRem(tk, id) {
            let _this = this;
            _this.loading = true;
            _this.post("/task/task/pay_rem", { tk: tk, block_id: id }, res => {
                _this.loading = false;
                if (res.code == 200) {
                    _this.$message.success("申请成功");
                    _this.$emit("reload");
                } else {
                    if (res.msg) {
                        _this.$message.success(res.msg);
                    } else {
                        _this.$message.success("申请失败");
                    }
                }
            });
        },

        //项目评价
        task_appraise(tk) {
            this.$router.push("/user/task/appraise?tk=" + tk);
        },

        //查看项目评价
        view_appraise(tk) {
            this.$router.push("/user/appraise");
        },

        //项目资料
        viewFile(tk) {
            this.$router.push("/user/task/file?tk=" + tk);
        },

        //申请调解
        appeal(tk) {
            if (this.task.trade.is_remuneration != 1) {
                this.$message.error(
                    "该项目未托管酬金，没有该权限。"
                );
                return false;
            }
            if (
                (this.task.status == 7 || this.task.status == 9) &&
                this.task.trade &&
                (this.task.trade.status == 3 || this.task.trade.status == 5)
            ) {
                this.$router.push("/user/task/appeal?tk=" + tk);
            } else {
                this.$message.error(
                    "该项目状态不是工作中，不可提交调解申请！"
                );
            }
        },

        //打开准备签署合同抽屉
        contractStep() {
            if (this.task.trade.is_remuneration != 1) {
                this.$message.error(
                    "该项目未托管酬金，没有该权限。"
                );
                return false;
            }
            this.$emit("contractStep");
        }
    },
    mounted() {
        let task_progress_status = sessionStorage.getItem(
            "task_progress_status"
        );

        if (task_progress_status == 1) {
            this.task_progress_box = true;
        } else {
            this.task_progress_box = false;
        }
    }
};
</script>

<style lang="less" scoped>
@import "../../../styles/todos2.less";
@import "../../../styles/todo.less";

/deep/ .el-step__title.is-process {
    color: #409eff !important;
    border-color: #409eff !important;
}

/deep/.el-step__head.is-process {
    color: #409eff !important;
    border-color: #409eff !important;
}

/deep/.el-step__main {
    .el-step__description {
        color: #409eff !important;
    }
}

/deep/.el-step__head.is-finish {
    .el-step__line {
        background: #409eff !important;
    }
}
</style>